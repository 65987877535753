import React from 'react';
import axios from 'axios';
import {BrowserRouter, Route, Redirect, Routes, Link, useLocation, useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';

import logo from './logo.svg';
import './App.css';

import FingerprintPage from './components/Fingerprint.js'
import EventList from './components/Event.js'
import FingerprintDetails from './components/FingerprintDetails.js'
import IncidentPage from './components/Incident.js'
import ResourcePage from './components/Resource.js'
import DeviceVisitStatistics from './components/DeviceVisitStatistics.js'
import CachePage from './components/Cache.js'
import LoginForm from './components/Auth.js'
import AdminForm from './components/Admin.js'
import AdminLogsForm from './components/AdminLogs.js'

const HOST_PRODUCTION = 'https://frap-test.iidx.ru'
const HOST_DEVELOPMENT = 'http://localhost:10009'

// !!! SHOULD BE THE SAME IN Fingerprint.js !!!
const FILTER_FINGERPRINT = "FINGERPRINT"
const FILTER_SESSION = "SESSION"
const FILTER_DEVICE = "DEVICE"
const FILTER_IP_ADDRESS = "IP_ADDRESS"

const PATH_FINGERPRINTS_PRODUCTION = "/frap/pixel/api/admin-app/user/listFingerprints?score=true&"
const PATH_EVENTS_PRODUCTION = "/frap/pixel/api/admin-app/user/listEvents?"
const PATH_INCIDENTS_PRODUCTION = "/frap/pixel/api/admin-app/user/listIncidents?"
const PATH_SCORE_AGGREGATES_PRODUCTION = "/frap/pixel/api/admin-app/user/getIncidentAggregates?"
const PATH_RESOURCES_PRODUCTION = "/frap/pixel/api/admin-app/user/listResources?"
const PATH_AUTHENTICATE_PRODUCTION = "/frap/pixel/api/auth/signin"
const PATH_REGISTER_PRODUCTION = "/frap/pixel/api/auth/signup"
const PATH_PERMIT_REGISTRATION_PRODUCTION = "/frap/pixel/api/auth/admin/allowsignup"
const PATH_REGISTER_CUSTOMER_PRODUCTION = "/frap/pixel/api/admin-app/admin/registerCustomer"
const PATH_DEVICE_VISIT_STATISTICS_PRODUCTION = "/frap/pixel/api/admin-app/manager/getDeviceVisitStatistics?"
const PATH_CACHE_NAMES_PRODUCTION = "/frap/pixel/api/admin-app/admin/listCacheNames"
const PATH_CACHE_STATE_PRODUCTION = "/frap/pixel/api/admin-app/admin/getCacheState?"
const PATH_CLEAR_CACHE_PRODUCTION = "/frap/pixel/api/admin-app/admin/clearCache?"
const PATH_GET_LOGGING_LEVEL_PRODUCTION = "/frap/pixel/api/admin-app/admin/getLoggingLevel"
const PATH_SET_LOGGING_LEVEL_PRODUCTION = "/frap/pixel/api/admin-app/admin/setLoggingLevel?"

const PATH_FINGERPRINTS_DEVELOPMENT = "/frap/pixel/api/admin-app/user/listFingerprints?score=true&"
const PATH_EVENTS_DEVELOPMENT = "/frap/pixel/api/admin-app/user/listEvents?"
const PATH_INCIDENTS_DEVELOPMENT = "/frap/pixel/api/admin-app/user/listIncidents?"
const PATH_SCORE_AGGREGATES_DEVELOPMENT = "/frap/pixel/api/admin-app/user/getIncidentAggregates?"
const PATH_RESOURCES_DEVELOPMENT = "/frap/pixel/api/admin-app/user/listResources?"
const PATH_AUTHENTICATE_DEVELOPMENT = "/frap/pixel/api/auth/signin"
const PATH_REGISTER_DEVELOPMENT = "/frap/pixel/api/auth/signup"
const PATH_PERMIT_REGISTRATION_DEVELOPMENT = "/frap/pixel/api/auth/admin/allowsignup"
const PATH_REGISTER_CUSTOMER_DEVELOPMENT = "/frap/pixel/api/admin-app/admin/registerCustomer"
const PATH_DEVICE_VISIT_STATISTICS_DEVELOPMENT = "/frap/pixel/api/admin-app/manager/getDeviceVisitStatistics?"
const PATH_CACHE_NAMES_DEVELOPMENT = "/frap/pixel/api/admin-app/admin/listCacheNames"
const PATH_CACHE_STATE_DEVELOPMENT = "/frap/pixel/api/admin-app/admin/getCacheState?"
const PATH_CLEAR_CACHE_DEVELOPMENT = "/frap/pixel/api/admin-app/admin/clearCache?"
const PATH_GET_LOGGING_LEVEL_DEVELOPMENT = "/frap/pixel/api/admin-app/admin/getLoggingLevel"
const PATH_SET_LOGGING_LEVEL_DEVELOPMENT = "/frap/pixel/api/admin-app/admin/setLoggingLevel?"

const PATH_FINGERPRINTS = (host) => {return host == HOST_PRODUCTION ? PATH_FINGERPRINTS_PRODUCTION : PATH_FINGERPRINTS_DEVELOPMENT}
const PATH_EVENTS = (host) => {return host == HOST_PRODUCTION ? PATH_EVENTS_PRODUCTION : PATH_EVENTS_DEVELOPMENT}
const PATH_INCIDENTS = (host) => {return host == HOST_PRODUCTION ? PATH_INCIDENTS_PRODUCTION : PATH_INCIDENTS_DEVELOPMENT}
const PATH_SCORE_AGGREGATES = (host) => {return host == HOST_PRODUCTION ? PATH_SCORE_AGGREGATES_PRODUCTION : PATH_SCORE_AGGREGATES_DEVELOPMENT}
const PATH_RESOURCES = (host) => {return host == HOST_PRODUCTION ? PATH_RESOURCES_PRODUCTION : PATH_RESOURCES_DEVELOPMENT}
const PATH_AUTHENTICATE = (host) => {return host == HOST_PRODUCTION ? PATH_AUTHENTICATE_PRODUCTION : PATH_AUTHENTICATE_DEVELOPMENT}
const PATH_REGISTER = (host) => {return host == HOST_PRODUCTION ? PATH_REGISTER_PRODUCTION : PATH_REGISTER_DEVELOPMENT}
const PATH_PERMIT_REGISTRATION = (host) => {return host == HOST_PRODUCTION ? PATH_PERMIT_REGISTRATION_PRODUCTION : PATH_PERMIT_REGISTRATION_DEVELOPMENT}
const PATH_REGISTER_CUSTOMER = (host) => {return host == HOST_PRODUCTION ? PATH_REGISTER_CUSTOMER_PRODUCTION : PATH_REGISTER_CUSTOMER_DEVELOPMENT}
const PATH_DEVICE_VISIT_STATISTICS = (host) => {return host == HOST_PRODUCTION ? PATH_DEVICE_VISIT_STATISTICS_PRODUCTION : PATH_DEVICE_VISIT_STATISTICS_DEVELOPMENT}
const PATH_CACHE_NAMES = (host) => {return host == HOST_PRODUCTION ? PATH_CACHE_NAMES_PRODUCTION : PATH_CACHE_NAMES_DEVELOPMENT}
const PATH_CACHE_STATE = (host) => {return host == HOST_PRODUCTION ? PATH_CACHE_STATE_PRODUCTION : PATH_CACHE_STATE_DEVELOPMENT}
const PATH_CLEAR_CACHE = (host) => {return host == HOST_PRODUCTION ? PATH_CLEAR_CACHE_PRODUCTION : PATH_CLEAR_CACHE_DEVELOPMENT}
const PATH_GET_LOGGING_LEVEL = (host) => {return host == HOST_PRODUCTION ? PATH_GET_LOGGING_LEVEL_PRODUCTION : PATH_GET_LOGGING_LEVEL_DEVELOPMENT}
const PATH_SET_LOGGING_LEVEL = (host) => {return host == HOST_PRODUCTION ? PATH_SET_LOGGING_LEVEL_PRODUCTION : PATH_SET_LOGGING_LEVEL_DEVELOPMENT}

const URL_FINGERPRINTS = (host, fingerprintId, sessionId, deviceId, ipAddress, pixelCode, timestamp) => `${host}${PATH_FINGERPRINTS(host)}${fingerprintId != "" ? "fingerprintId="+fingerprintId+"&" : ""}${sessionId != "" ? "sessionId="+sessionId+"&" : ""}${deviceId != "" ? "deviceId="+deviceId+"&" : ""}${ipAddress != "" ? "ipAddress="+ipAddress+"&" : ""}${pixelCode != "" ? "pixelCode="+pixelCode+"&" : ""}${timestamp != "" ? "timestamp="+timestamp+"&" : ""}`
const URL_EVENTS = (host) => `${host}${PATH_EVENTS(host)}`
const URL_INCIDENTS = (host) => `${host}${PATH_INCIDENTS(host)}`
const URL_INCIDENTS_FILTERED = (host, parentType, parentId) => `${host}${PATH_INCIDENTS(host)}parentType=${parentType}&parentId=${parentId}&`
const URL_SCORE_AGGREGATES = (host, parentType, parentId) => `${host}${PATH_SCORE_AGGREGATES(host)}parentType=${parentType}&parentId=${parentId}`
const URL_RESOURCES = (host) => `${host}${PATH_RESOURCES(host)}`
const URL_AUTHENTICATE = (host) => `${host}${PATH_AUTHENTICATE(host)}`
const URL_REGISTER = (host) => `${host}${PATH_REGISTER(host)}`
const URL_PERMIT_REGISTRATION = (host) => `${host}${PATH_PERMIT_REGISTRATION(host)}`
const URL_REGISTER_CUSTOMER = (host) => `${host}${PATH_REGISTER_CUSTOMER(host)}`
const URL_DEVICE_VISIT_STATISTICS_FOR_CUSTOMER = (host, customerId, fromDate, toDate) => `${host}${PATH_DEVICE_VISIT_STATISTICS(host)}customerId=${customerId}&${fromDate != "" ? "from="+fromDate+"&" : ""}${toDate != "" ? "to="+toDate+"&" : ""}`
const URL_CACHE_NAMES = (host) => `${host}${PATH_CACHE_NAMES(host)}`
const URL_CACHE_STATE = (host, cacheName) => `${host}${PATH_CACHE_STATE(host)}cacheName=${cacheName}&`
const URL_CLEAR_CACHE = (host, cacheName) => `${host}${PATH_CLEAR_CACHE(host)}cacheName=${cacheName}&`
const URL_GET_LOGGING_LEVEL = (host) => `${host}${PATH_GET_LOGGING_LEVEL(host)}`
const URL_SET_LOGGING_LEVEL = (host, level) => `${host}${PATH_SET_LOGGING_LEVEL(host)}level=${level}&`

const URL_FE_FINGERPRINT = 'fingerprint'
const URL_FE_LOGIN = "/login"

const INTERVAL_DATA_REFRESH = 60000
const PAGE_SIZE_FINGERPRINTS = 15
const PAGE_SIZE_EVENTS = 20
const PAGE_SIZE_INCIDENTS = 20
const PAGE_SIZE_RESOURCES = 20
const PAGE_SIZE_CACHES = 30

const GetPagedUrl = (url, page, size) => {
  return url + "page=" + page + "&size=" + size
}

const NotFound404 = () => {
  const location = useLocation()
  return (
    <div>
      <h1>Page '{location.pathname}' not found</h1>
    </div>
  )
}

// Convert timestamp to string formatted as yyyy/mm/dd HH:mi:ss.mms
const TimestampToDateTimeMap = (ts) => {
//  console.log(typeof ts);
  const padZero = (value, len = 2) => `${value}`.padStart(len, '0');
  let dateMap = {
    yyyy: ts.getFullYear(),
    mm: ts.getMonth() + 1,
    dd: ts.getDate(),
    HH: ts.getHours(),
    mi: ts.getMinutes(),
    ss: ts.getSeconds(),
    mms: ts.getMilliseconds(),
  };
  Object.keys(dateMap).filter(k => k !== 'yyyy').forEach(k => dateMap[k] = padZero(dateMap[k], k === 'mms' ? 3 : 2));
  return dateMap;
}

// Convert timestamp to string formatted as yyyy/mm/dd HH:mi:ss.mms
const TimestampToString = (ts) => {
  const dateMap = TimestampToDateTimeMap(ts);
  return `${dateMap.yyyy}/${dateMap.mm}/${dateMap.dd} ${dateMap.HH}:${dateMap.mi}:${dateMap.ss}`
}

// Convert timestamp to string formatted as yyyy-mm-ddTHH:mi:ss.mms
const TimestampToISOString = (ts) => {
  const dateMap = TimestampToDateTimeMap(ts);
  return `${dateMap.yyyy}-${dateMap.mm}-${dateMap.dd}T${dateMap.HH}:${dateMap.mi}:${dateMap.ss}`
}

//const PrivateRoute = ({ isLoggedIn, ...props }) =>
//  isLoggedIn
//    ? <Route { ...props } />
//    : <Redirect to={URL_FE_LOGIN} />

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      'fingerprints': [],
      'fingerprintsPage': 0,
      'fingerprintsPageSize': PAGE_SIZE_FINGERPRINTS,
      'fingerprintsFilter': {
        'fingerprintId': "",
        'sessionId': "",
        'deviceId': "",
        'ipAddress': "",
        'pixelCode': "",
        'timestamp': ""
      },
      'fingerprintsFilterModeReplace': true,
      'events': [],
      'eventsPage': 0,
      'eventsPageSize': PAGE_SIZE_EVENTS,
      'incidents': [],
      'incidentsPage': 0,
      'incidentsPageSize': PAGE_SIZE_INCIDENTS,
      'incidentsParentType': "",
      'incidentsParentId': "",
      'incidentsScoreAggregates': [],
      'resources': [],
      'resourcesPage': 0,
      'resourcesPageSize': PAGE_SIZE_RESOURCES,
      'deviceVisitStatistics': "",
      'deviceVisitStatisticsLoadedCustomerId': "",
      'deviceVisitStatisticsLoadedFromDate': "",
      'deviceVisitStatisticsLoadedToDate': "",
      'deviceVisitStatisticsCustomerId': "",
      'deviceVisitStatisticsFromDate': "",
      'deviceVisitStatisticsToDate': "",
      'cacheNames': [],
      'cacheName': "",
      'caches': [],
      'cachesPage': 0,
      'cachesPageSize': PAGE_SIZE_CACHES,
      'time': new Date(),
      'authStatus': {
        [HOST_PRODUCTION]: {
          'isLoggedIn': false,
          'authData': undefined
        },
        [HOST_DEVELOPMENT]: {
          'isLoggedIn': false,
          'authData': undefined
        }
      },
      'authData': undefined,
      'host': HOST_PRODUCTION
    }
  }

  switchHost() {
    this.setState({'host': (this.state.host == HOST_PRODUCTION ? HOST_DEVELOPMENT : HOST_PRODUCTION)}, () => this.loadDataFromServer())
  }

  setAuthData(authData) {
    console.log("setAuthData");
    const cookies = new Cookies()
    // Update status for current host
    let authStatus = {}
    authStatus[this.state.host] = {
      'isLoggedIn': authData != undefined,
      'authData': authData
    }
    // Copy status from other host
    let otherHost = this.state.host == HOST_PRODUCTION ? HOST_DEVELOPMENT : HOST_PRODUCTION;
    authStatus[otherHost] = this.state.authStatus[otherHost]
    // Save cookie
    console.log(authStatus)
    cookies.set('authStatus', authStatus)
    this.setState({'authStatus': authStatus}, () => this.loadDataFromServer())    // refresh data in callback
  }

  isAuthenticated() {
//    console.log("isAuthenticated");
//    console.log(this.state.host)
//    console.log(this.state.authStatus);
//    console.log(this.state.authStatus[this.state.host])
    return (this.state.authStatus[this.state.host].isLoggedIn)
  }

  isAdmin() {
    return this.state.authStatus[this.state.host].authData?.roles != undefined ? this.state.authStatus[this.state.host].authData?.roles?.indexOf("ROLE_ADMIN") != -1 : false;
  }

  isManager() {
    return this.state.authStatus[this.state.host].authData?.roles != undefined ? this.state.authStatus[this.state.host].authData.roles.indexOf("ROLE_MANAGER") != -1 : false;
  }

  isUser() {
    return this.state.authStatus[this.state.host].authData?.roles != undefined ? this.state.authStatus[this.state.host].authData.roles.indexOf("ROLE_USER") != -1 : false;
  }

  getCurrentRoles() {
    var roles = [];
    if (this.isAdmin()) roles.push("ADMIN");
    if (this.isManager()) roles.push("MANAGER");
    if (this.isUser()) roles.push("USER");
    if (roles.length == 0) {
      return "NO ROLES";
    } else {
      return roles.join(",");
    }
  }

  getCurrentUser() {
//    return this.isAuthenticated() ? this.state.authStatus[this.state.host].authData.username : ""
    if (this.isAuthenticated()) {
      const authData = this.state.authStatus[this.state.host].authData
      return authData.username + " [" + authData.firstName + " " + authData.lastName +
             " (" + this.getCurrentRoles() + "), " +
             authData.organizationNickname + "]"
    } else {
      return ""
    }
  }

  setNotLoggedIn() {
    this.state.authStatus[this.state.host].isLoggedIn = false;
  }

  logout() {
    this.setAuthData(undefined)
  }

  getAuthData() {
    console.log("getAuthData")
    const cookies = new Cookies()
    const authStatus = cookies.get('authStatus')
    console.log(authStatus)
    if (authStatus != undefined) {      // Set status only if there was the cookie
      this.setState({'authStatus': authStatus}, () => this.loadDataFromServer())    // refresh data in callback
    } else {
      this.loadDataFromServer()
    }
  }

  login(username, password) {
    console.log('login: ' + this.state.host + ' ' + username)
    axios.post(URL_AUTHENTICATE(this.state.host), {username: username, password: password}).then(response => {
      this.setAuthData(response.data)
//      this.setToken(response.data['token'])
      console.log(response.data)
    }).catch(error =>
      alert('Authentication failure: ' + error.message + ((error.response?.status == 401) ? ': Bad login information provided' : ''))
    )
  }

  registerUser(username, password, email, token) {
    console.log('register: ' + username + ' ' + password + ' ' + email + ' ' + token)
    axios.post(URL_REGISTER(this.state.host),
               {username: username, password: password, email: email, role: ["user"], registrationToken: token}).then(response => {
      console.log(response.data)
      alert(response.data.message)
    }).catch(error =>
      alert('Authentication failure: ' + error.message + ((error.response?.status == 401) ? ': Bad sign-up information provided' : ''))
    )
  }

  permitUserRegistration(firstName, middleName, lastName, organizationNickname, displayResponse) {
    if (this.isAuthenticated()) {
      console.log('permit registration: ' + firstName + ' ' + middleName + ' ' + lastName + ' ' + organizationNickname)
      const headers = this.getHeaders()
      axios.post(URL_PERMIT_REGISTRATION(this.state.host),
                 {firstName: firstName, middleName: middleName, lastName: lastName, organizationNickname: organizationNickname}, {headers}).then(response => {
        console.log(response.data);
        displayResponse(response);
      }).catch(error => {
        alert('Permit registration failure: ' + error.message);
        displayResponse(null);
      })
    } else {
      console.error("Not authenticated to permit registration")
    }
  }

  registerCustomer(nickname, organizationNickname, displayResponse) {
    if (this.isAuthenticated()) {
      console.log('register customer: ' + nickname + ' ' + organizationNickname)
      const headers = this.getHeaders()
      axios.post(URL_REGISTER_CUSTOMER(this.state.host),
                 {nickname: nickname, organizationNickname: organizationNickname}, {headers}).then(response => {
        console.log(response.data);
        displayResponse(response);
      }).catch(error => {
        alert('Register customer failure: ' + error.message);
        displayResponse(null);
      })
    } else {
      console.error("Not authenticated to register customer")
    }
  }

  getLoggingLevel(displayResponse) {
    if (this.isAuthenticated()) {
      console.log('get logging level')
      const headers = this.getHeaders()

      axios.get(URL_GET_LOGGING_LEVEL(this.state.host), {headers})
        .then(response => {
          console.log('logging level is ' + response.data)
          displayResponse(response.data)
        }).catch(error => {
          console.error('Cannot get logging level: ' + error.message)
//          if (error.response.status == 401) this.setNotLoggedIn();
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
          displayResponse("<error>")
        })
    } else {
      console.error("Not authenticated to get logging level")
      displayResponse("<not authenticated>")
    }
  }

  setLoggingLevel(level, displayResponse) {
    if (this.isAuthenticated()) {
      console.log('set logging level')
      const headers = this.getHeaders()

      axios.get(URL_SET_LOGGING_LEVEL(this.state.host, level), {headers})
        .then(response => {
          console.log('logging level set to ' + response.data)
          displayResponse(response.data)
        }).catch(error => {
          console.error('Cannot set logging level: ' + error.message)
//          if (error.response.status == 401) this.setNotLoggedIn();
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
          displayResponse("<error>")
        })
    } else {
      console.error("Not authenticated to set logging level")
      displayResponse("<not authenticated>")
    }
  }

  getHeaders() {
    let headers = {'Content-Type': 'application/json'}
    if (this.isAuthenticated()) {
      let authData = this.state.authStatus[this.state.host].authData
//      console.log(authData.token + " " + authData.tokenType + " " + authData.username)
      headers['Authorization'] = authData.tokenType + " " + authData.token
    }
    return headers
  }

  loadFingerprintsFromServer() {
    if (this.isAuthenticated()) {
//      console.log("Loading fingerprints")
      const headers = this.getHeaders()

      // determine URL
      const filter = this.state.fingerprintsFilter;
      let url = URL_FINGERPRINTS(this.state.host, filter.fingerprintId, filter.sessionId, filter.deviceId, filter.ipAddress, filter.pixelCode, filter.timestamp);
      console.log(url);

      //   load fingerprints
      axios.get(GetPagedUrl(url, this.state.fingerprintsPage, this.state.fingerprintsPageSize), {headers})
        .then(response => {
          this.setState({'fingerprints': response.data})
        }).catch(error => {
          console.log(error)
          this.setState({'fingerprints': []})
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
        })
    }
  }

  loadIncidentsFromServer() {
    if (this.isAuthenticated()) {
      const headers = this.getHeaders();

      // determine URLs
      let urlIncidents;
      let urlScoreAggregates;
      if (this.state.incidentsParentType != "") {
        urlIncidents = URL_INCIDENTS_FILTERED(this.state.host, this.state.incidentsParentType, this.state.incidentsParentId);
        urlScoreAggregates = URL_SCORE_AGGREGATES(this.state.host, this.state.incidentsParentType, this.state.incidentsParentId);
      } else {
        urlIncidents = URL_INCIDENTS(this.state.host);
        urlScoreAggregates = "";              // no score aggregates for all the incidents
      }

      // load incidents
      axios.get(GetPagedUrl(urlIncidents, this.state.incidentsPage, this.state.incidentsPageSize), {headers})
        .then(response => {
          this.setState({'incidents': response.data})
        }).catch(error => {
          console.log(error)
          this.setState({'incidents': []})
//          if (error.response.status == 401) this.setNotLoggedIn();
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
        })

      // load score aggregates if needed
      if (urlScoreAggregates != "") {
        axios.get(urlScoreAggregates, {headers})
          .then(response => {
//            console.log(response.data);
            this.setState({'incidentsScoreAggregates': response.data});
          }).catch(error => {
            console.log(error);
            this.setState({'incidentsScoreAggregates': []});
            if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
          })
      } else {
        this.setState({'incidentsScoreAggregates': []});
      }
    }
  }

  loadEventsFromServer() {
    if (this.isAuthenticated()) {
      const headers = this.getHeaders()
      //   events page
      axios.get(GetPagedUrl(URL_EVENTS(this.state.host), this.state.eventsPage, this.state.eventsPageSize), {headers})
        .then(response => {
          this.setState({'events': response.data})
        }).catch(error => {
          console.log(error)
          this.setState({'events': []})
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
        })
    }
  }

  loadResourcesFromServer() {
    if (this.isAuthenticated()) {
      const headers = this.getHeaders()
      //   resources page
      axios.get(GetPagedUrl(URL_RESOURCES(this.state.host), this.state.resourcesPage, this.state.resourcesPageSize), {headers})
        .then(response => {
          this.setState({'resources': response.data})
        }).catch(error => {
          console.log(error)
          this.setState({'resources': []})
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
        })
    }
  }

  loadDeviceVisitStatisticsFromServer() {
//    console.log("customer", this.state.deviceVisitStatisticsLoadedCustomerId, "->", this.state.deviceVisitStatisticsCustomerId);
//    console.log("from", this.state.deviceVisitStatisticsLoadedFromDate, "->", this.state.deviceVisitStatisticsFromDate);
//    console.log("to", this.state.deviceVisitStatisticsLoadedToDate, "->", this.state.deviceVisitStatisticsToDate);
    if (this.isAuthenticated() &&
        this.state.deviceVisitStatisticsCustomerId != "" &&
        this.isManager() &&
        (this.state.deviceVisitStatisticsLoadedCustomerId != this.state.deviceVisitStatisticsCustomerId ||
         this.state.deviceVisitStatisticsLoadedFromDate != this.state.deviceVisitStatisticsFromDate ||
         this.state.deviceVisitStatisticsLoadedToDate != this.state.deviceVisitStatisticsToDate)
        ) {
      const headers = this.getHeaders()
      //   device visit statistics page
      console.log(URL_DEVICE_VISIT_STATISTICS_FOR_CUSTOMER(this.state.host, this.state.deviceVisitStatisticsCustomerId, this.state.deviceVisitStatisticsFromDate, this.state.deviceVisitStatisticsToDate));
      axios.get(URL_DEVICE_VISIT_STATISTICS_FOR_CUSTOMER(this.state.host, this.state.deviceVisitStatisticsCustomerId, this.state.deviceVisitStatisticsFromDate, this.state.deviceVisitStatisticsToDate), {headers})
        .then(response => {
          this.setState({'deviceVisitStatistics': response.data,
                         'deviceVisitStatisticsLoadedCustomerId': this.state.deviceVisitStatisticsCustomerId,
                         'deviceVisitStatisticsLoadedFromDate': this.state.deviceVisitStatisticsFromDate,
                         'deviceVisitStatisticsLoadedToDate': this.state.deviceVisitStatisticsToDate})
        }).catch(error => {
          console.log(error)
          this.setState({'deviceVisitStatistics': "",
                         'deviceVisitStatisticsLoadedCustomerId': "",
                         'deviceVisitStatisticsLoadedFromDate': "",
                         'deviceVisitStatisticsLoadedToDate': ""})
//          if (error.response.status == 401) this.setNotLoggedIn();
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
        })
    } else {
      console.log("not reloading statistics")
    }
  }

  loadCacheNamesFromServer() {
    if (this.isAuthenticated() && this.isAdmin()) {
      const headers = this.getHeaders()
      //   caches page
      axios.get(URL_CACHE_NAMES(this.state.host), {headers})
        .then(response => {
          console.log(response.data)
          this.setState({'cacheNames': response.data})
        }).catch(error => {
          console.log(error)
          this.setState({'cacheNames': []})
          if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
        })
    } else {
      console.log("not reloading cache names")
    }
  }

  loadCachesFromServer() {
    if (this.isAuthenticated() && this.isAdmin()) {
      const headers = this.getHeaders()
      //   caches page
      if (this.state.cacheName != "") {
        axios.get(GetPagedUrl(URL_CACHE_STATE(this.state.host, this.state.cacheName), this.state.cachesPage, this.state.cachesPageSize), {headers})
          .then(response => {
            console.log(response.data)
            this.setState({'caches': response.data})
          }).catch(error => {
            console.log(error)
            this.setState({'caches': []})
            if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
          })
      } else if (this.state.caches.length != 0) {
        // clear caches if cache name just got null
        this.setState({'caches': []})
      }
    } else {
      console.log("not reloading caches")
    }
  }

  // clear and then reload caches
  clearCaches() {
    if (this.isAuthenticated()) {
      const headers = this.getHeaders()
      //   clear caches
      if (this.state.cacheName != "") {
        axios.get(URL_CLEAR_CACHE(this.state.host, this.state.cacheName), {headers})
          .then(response => {
            console.log(response.data)
            this.loadCachesFromServer()
          }).catch(error => {
            console.log(error)
            if (error.response == null || error.response.status == 401) this.setNotLoggedIn();
          })
      }
    }
  }

  loadDataFromServer() {
    // load data from server
    this.loadFingerprintsFromServer();               // fingerprint page
    this.loadIncidentsFromServer();                     // incidents page
    this.loadEventsFromServer();                        // events page
    this.loadResourcesFromServer();                     // resources page
    this.loadDeviceVisitStatisticsFromServer();         // device visit statistics
    this.loadCacheNamesFromServer();                // cache names
    this.loadCachesFromServer();                    // cache stats
    // indicate retrieval time
    this.setState({'time': new Date()})
  }

  calculatePageNum(currentPageNum, pageIncrement) {
    return pageIncrement == 0
                ? 0
                : (currentPageNum + pageIncrement >= 0
                        ? currentPageNum + pageIncrement
                        : currentPageNum);
  }

  setFingerprintPage(pageIncrement) {
    const pageNum = this.calculatePageNum(this.state.fingerprintsPage, pageIncrement);
    if (pageNum != this.state.fingerprintsPage) {
      this.setState({'fingerprintsPage': pageNum}, () => this.loadFingerprintsFromServer())
    }
  }

  setIncidentPage(pageIncrement) {
    const pageNum = this.calculatePageNum(this.state.incidentsPage, pageIncrement);
    if (pageNum != this.state.incidentsPage) {
      this.setState({'incidentsPage': pageNum}, () => this.loadIncidentsFromServer())
    }
  }

  setEventPage(pageIncrement) {
    const pageNum = this.calculatePageNum(this.state.eventsPage, pageIncrement);
    if (pageNum != this.state.eventsPage) {
      this.setState({'eventsPage': pageNum}, () => this.loadEventsFromServer())
    }
  }

  setResourcePage(pageIncrement) {
    const pageNum = this.calculatePageNum(this.state.resourcesPage, pageIncrement);
    if (pageNum != this.state.resourcesPage) {
      this.setState({'resourcesPage': pageNum}, () => this.loadResourcesFromServer())
    }
  }

  setCachePage(pageIncrement) {
    const pageNum = this.calculatePageNum(this.state.cachesPage, pageIncrement);
    if (pageNum != this.state.cachesPage) {
      this.setState({'cachesPage': pageNum}, () => this.loadCachesFromServer())
    }
  }

  getFingerprintPage() { return this.state.fingerprintsPage; }
  getIncidentPage() { return this.state.incidentsPage; }
  getEventPage() { return this.state.eventsPage; }
  getResourcePage() { return this.state.resourcesPage; }
  getCachePage() { return this.state.cachesPage; }

  setIncidentsParent(parentType, parentId) {
    if (parentType != this.state.incidentsParentType || parentId != this.state.incidentsParentId) {
      if (parentType != "" && parentType != null && parentType != "null" &&
          parentId != "" && parentId != null && parentId != "null") {
        console.log("setting incident parent type to " + parentType + " and id to " + parentId + " and loading its incidents");
        this.setState({'incidentsParentType': parentType, 'incidentsParentId': parentId, 'incidentsPage': 0},
                      () => this.loadIncidentsFromServer());
      } else if (this.state.incidentsParentType != "" || this.state.incidentsParentId != "") {
        console.log("clearing incidents parent and loading all incidents");
        this.setState({'incidentsParentType': "", 'incidentsParentId': "", 'incidentsPage': 0},
                      () => this.loadIncidentsFromServer());
      }
    } else {
        console.log("incident parent remains the same");
    }
  }

  nullAndUndefinedToEmptyString(sourceString) {
    if (sourceString == null || sourceString == "null" || sourceString == undefined || sourceString == "undefined") {
      return "";
    } else {
      return sourceString.trim();
    }
  }

  setFingerprintsFilter(filter) {
    // normalize whitespace, null and undefined
    filter.fingerprintId = this.nullAndUndefinedToEmptyString(filter.fingerprintId);
    filter.sessionId = this.nullAndUndefinedToEmptyString(filter.sessionId);
    filter.deviceId = this.nullAndUndefinedToEmptyString(filter.deviceId);
    filter.ipAddress = this.nullAndUndefinedToEmptyString(filter.ipAddress);
    filter.pixelCode = this.nullAndUndefinedToEmptyString(filter.pixelCode);
    filter.timestamp = this.nullAndUndefinedToEmptyString(filter.timestamp);

    // get filter state
    const filterState = this.state.fingerprintsFilter;

    // only load fingerprints if filter changed
    if (filter.fingerprintId != filterState.fingerprintId ||
        filter.sessionId != filterState.sessionId ||
        filter.deviceId != filterState.deviceId ||
        filter.ipAddress != filterState.ipAddress ||
        filter.pixelCode != filterState.pixelCode ||
        filter.timestamp != filterState.timestamp) {

        this.setState({'fingerprintsFilter': filter,
                       'fingerprintsPage': 0},
                      () => this.loadFingerprintsFromServer());
    } else {
        console.log("fingerprint filter remains the same");
    }
  }

  getFingerprintFilterSettings() {
    return this.state.fingerprintsFilter;
  }

  setDeviceVisitStatisticsCustomerId(id) {
    if (id != this.state.deviceVisitStatisticsLoadedCustomerId) {
      if (id != "" && id != null && id != "null") {
        console.log("setting customer id and loading device visit statistics for customer " + id)
        this.setState({'deviceVisitStatisticsCustomerId': id}, () => this.loadDeviceVisitStatisticsFromServer());
      } else if (this.state.deviceVisitStatisticsCustomerId != "") {
        console.log("clearing  device visit statistics")
        this.setState({'deviceVisitStatisticsCustomerId': "", 'deviceVisitStatisticsLoadedCustomerId': "",
                       'deviceVisitStatistics': []});
      }
    } else {
        console.log("customer id remains the same");
        // maybe something else changed - e.g. dates, let load function decide whether to reload
        this.loadDeviceVisitStatisticsFromServer();
    }
  }

  setCacheName(cacheName) {
    cacheName = this.nullAndUndefinedToEmptyString(cacheName);
    if (cacheName != this.state.cacheName) {
      console.log("setting cache name to " + cacheName + " and loading caches");
      this.setState({'cacheName': cacheName, 'cachesPage': 0},
                    () => this.loadCachesFromServer());
    } else {
        console.log("cache name remains the same");
    }
  }

  setStateVar(name, value) {
    this.setState({[name]: value}, () => console.log("status var", name, "value", value));
  }

  getStateVar(name) {
    return this.state[name];
  }

  componentWillUnmount() {
    // Clear automatic page refresh
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.getAuthData();
    // Configure automatic data refresh interval
    this.interval = setInterval(() => this.loadDataFromServer(), INTERVAL_DATA_REFRESH);
  }

  render (props) {
    return (
      <div className="App">
        { TimestampToString(this.state.time) + " - " + this.state.host + "(" + (this.isAuthenticated() ? this.getCurrentUser() : "<not logged in>") + ")"}
        <button onClick={() => this.switchHost()}>Switch Host</button>
        <button onClick={() => this.loadDataFromServer()}>Refresh</button>
        <p></p>
        <BrowserRouter>
          <nav>
            {this.isAuthenticated() ? <button onClick={() => this.logout()}>Logout</button> : <Link to={URL_FE_LOGIN}>Login</Link>} |
            <Link to='/' >Fingerprints</Link> |
            <Link to='/incidents' onClick={() => this.setIncidentsParent("", "")}>Incidents</Link> |
            <Link to='/events'>Events</Link> |
            {this.isManager() ?
              <>
              <Link to='/resourceStatistics'>Resource statistics</Link> |
              </>
              : null}
            {this.isAdmin() ?
              <>
              <Link to='/cacheState'>Cache state</Link> |
              <Link to='/admin'>Admin</Link> |
              </>
              : null}
          </nav>
          <Routes>
            <Route exact path={URL_FE_LOGIN} element={<LoginForm login={(username, password) => this.login(username, password)} registerUser={(username, password, email, token) => this.registerUser(username, password, email, token)}/>} />
            <Route exact path='/admin' element={<AdminForm permitUserRegistration={(firstName, middleName, lastName, organizationNickname, displayResponse) => this.permitUserRegistration(firstName, middleName, lastName, organizationNickname, displayResponse)} registerCustomer={(nickname, organizationNickname, displayResponse) => this.registerCustomer(nickname, organizationNickname, displayResponse)} />} />
            <Route exact path='/admin/logs' element={<AdminLogsForm getLoggingLevel={(displayResponse) => this.getLoggingLevel(displayResponse)} setLoggingLevel={(level, displayResponse) => this.setLoggingLevel(level, displayResponse)} />} />
            <Route exact path='/' element={<FingerprintPage isAuthenticated={() => this.isAuthenticated()} getFingerprintPage={() => this.getFingerprintPage()} setFingerprintPage={(pageIncrement) => this.setFingerprintPage(pageIncrement)} urlLogin={URL_FE_LOGIN} fingerprints={this.state.fingerprints} resources={this.state.resources} fingerprintsUrl={URL_FE_FINGERPRINT} loadFilteredIncidents={(parentType, parentId) => this.setIncidentsParent(parentType, parentId)} loadFilteredFingerprints={(filter) => this.setFingerprintsFilter(filter)} getFingerprintFilterSettings={() => this.getFingerprintFilterSettings()} setFilterModeReplace={(mode) => this.setStateVar('fingerprintsFilterModeReplace', mode)} getFilterModeReplace={() => this.getStateVar('fingerprintsFilterModeReplace')} />} />
            <Route exact path='/events' element={<EventList isAuthenticated={() => this.isAuthenticated()} getEventPage={() => this.getEventPage()} setEventPage={(pageIncrement) => this.setEventPage(pageIncrement)} urlLogin={URL_FE_LOGIN} events={this.state.events} />} />
            <Route exact path='/incidents' element={<IncidentPage isAuthenticated={() => this.isAuthenticated()} getIncidentPage={() => this.getIncidentPage()} setIncidentPage={(pageIncrement) => this.setIncidentPage(pageIncrement)} urlLogin={URL_FE_LOGIN} incidents={this.state.incidents} showScoreAggregates='false' />} />
            <Route exact path='/resourceStatistics' element={<ResourcePage isAuthenticated={() => this.isAuthenticated()} getResourcePage={() => this.getResourcePage()} setResourcePage={(pageIncrement) => this.setResourcePage(pageIncrement)} urlLogin={URL_FE_LOGIN} resources={this.state.resources} loadDeviceVisitStatistics={(id) => this.setDeviceVisitStatisticsCustomerId(id)} getStateVar={(name) => this.getStateVar(name)} setStateVar={(name, value) => this.setStateVar(name, value)} dateToStringConversionFunc={(timestamp) => TimestampToISOString(timestamp)} />} />
            <Route exact path='/deviceVisitStatistics' element={<DeviceVisitStatistics statistics={this.state.deviceVisitStatistics} />} />
            <Route exact path='/cacheState' element={<CachePage isAuthenticated={() => this.isAuthenticated()} getCachePage={() => this.getCachePage()} setCachePage={(pageIncrement) => this.setCachePage(pageIncrement)} urlLogin={URL_FE_LOGIN} caches={this.state.caches} loadCacheState={() => this.loadCachesFromServer()} clearCache={() => this.clearCaches()} cacheNames={this.state.cacheNames} setCacheName={(cacheName) => this.setCacheName(cacheName)} getCacheName={() => this.getStateVar("cacheName")} />} />
            <Route path={`/${URL_FE_FINGERPRINT}/:id`} element={<FingerprintDetails fingerprints={this.state.fingerprints} />} />
            <Route path={`/incidents/fingerprint/:id`} element={<IncidentPage isAuthenticated={() => this.isAuthenticated()} getIncidentPage={() => this.getIncidentPage()} setIncidentPage={(pageIncrement) => this.setIncidentPage(pageIncrement)} urlLogin={URL_FE_LOGIN} incidents={this.state.incidents} showScoreAggregates='true' scoreAggregates={this.state.incidentsScoreAggregates} />} />
            <Route path={`/incidents/session/:id`} element={<IncidentPage isAuthenticated={() => this.isAuthenticated()} getIncidentPage={() => this.getIncidentPage()} setIncidentPage={(pageIncrement) => this.setIncidentPage(pageIncrement)} urlLogin={URL_FE_LOGIN} incidents={this.state.incidents} showScoreAggregates='true' scoreAggregates={this.state.incidentsScoreAggregates} />} />
            <Route path={`/incidents/device/:id`} element={<IncidentPage isAuthenticated={() => this.isAuthenticated()} getIncidentPage={() => this.getIncidentPage()} setIncidentPage={(pageIncrement) => this.setIncidentPage(pageIncrement)} urlLogin={URL_FE_LOGIN} incidents={this.state.incidents} showScoreAggregates='true' scoreAggregates={this.state.incidentsScoreAggregates} />} />
            <Route path='*' element={<NotFound404 />} />
          </Routes>
        </BrowserRouter>
        <p></p>
      </div>
    )
  }

}

export default App;
