import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

const EventItem = ({event}) => {
  return (
    <tr>
      <td>{event.id}</td>
      <td>{event.timestamp}</td>
      <td>{event.customerTag}</td>
      <td>{event.eventTag}</td>
      <td>{event.metaData}</td>
    </tr>
  )
}

const EventList = ({isAuthenticated, getEventPage, setEventPage, urlLogin, events}) => {

  const navigate = useNavigate();
  useEffect(() => { if (!isAuthenticated()) navigate(urlLogin); }, []);

  return (
    <>
    <h2>Events</h2>
    <button onClick={() => setEventPage(0)}>First page</button>
    <button onClick={() => setEventPage(-1)}>Previous page</button>
    Page: { getEventPage() + 1 }
    <button onClick={() => setEventPage(1)}>Next page</button>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Event Timestamp</th>
          <th>Customer Tag</th>
          <th>Event Tag</th>
          <th>Metadata</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event) => <EventItem key={event.id} event={event} />)}
      </tbody>
    </table>
    </>
  )
}

export default EventList