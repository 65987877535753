import React from 'react';
import {useParams} from 'react-router-dom';


const FingerprintParameter = ({parameter}) => {
  const [key, value] = parameter;
  if (key.startsWith('json') || key.startsWith('error') || key.startsWith('score')) return;
  return (
    <tr>
      <td align="left">{key}</td>
      <td align="left">{value instanceof Object ? '(Object)' : value == null ? '' : !value ? '' : ''+value}</td>
    </tr>
  );
}

const FingerprintScore = ({parameter}) => {
  const [key, value] = parameter;
  if (!(key.startsWith('json') || key.startsWith('error') || key.startsWith('score'))) return;
  return (
    <tr>
      <td align="left">{key}</td>
      <td align="left">{value instanceof Object ? '(Object)' : value == null ? '' : !value ? '' : ''+value}</td>
    </tr>
  );
}

const FingerprintAll = ({parameter}) => {
  const [key, value] = parameter;
  return (
    <tr>
      <td align="left">{key}</td>
      <td align="left">{value instanceof Object ? '(Object)' : value == null ? '' : !value ? '' : ''+value}</td>
    </tr>
  );
}

const FingerprintDetails = ({fingerprints}) => {
  let {id} = useParams();
  let filtered_fingerprints = fingerprints.filter((fingerprint) => fingerprint.id == id)
  let fingerprint = filtered_fingerprints[0]
  return (
    <table>
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(fingerprint).map((parameter) => <FingerprintScore key={parameter.key} parameter={parameter} />)}
        <tr><td>---- RAW ----</td><td></td></tr>
        {Object.entries(fingerprint.raw).map((parameter) => <FingerprintAll key={parameter.key} parameter={parameter} />)}
        <tr><td>-------------</td><td></td></tr>
        {Object.entries(fingerprint).map((parameter) => <FingerprintParameter key={parameter.key} parameter={parameter} />)}
      </tbody>
    </table>
  )
}

export default FingerprintDetails
