import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'

const IncidentScoreCriticalThreshold = 99;
const IncidentInternalCriticalThreshold = 99;


const ScoreAggregateItem = ({scoreAggregate}) => {

//  console.log("ScoreAggregateItem: Score aggregate:")
//  console.log(scoreAggregate);

  return (
    <tr>
      <td>{scoreAggregate.code}</td>
      <td>{scoreAggregate.message}</td>
      <td>{scoreAggregate.name}</td>
      <td>{scoreAggregate.score}</td>
    </tr>
  )
}

const ScoreAggregateList = ({scoreAggregates}) => {

//  console.log("ScoreAggregateList: Score aggregates:")
//  console.log(scoreAggregates);

  return (
    <table>
      <thead>
        <tr>
          <th>Code</th>
          <th>Message</th>
          <th>Name</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        {scoreAggregates.map((scoreAggregate) => <ScoreAggregateItem scoreAggregate={scoreAggregate} />)}
      </tbody>
    </table>
  )
}

const GetIncidentRowScoreClass = (incident) => {
  return incident.usage == "I"
                ? (incident.score > IncidentInternalCriticalThreshold
                        ? "internalIncidentCritical"
                        : "internalIncidentWarning")
                : (incident.score > IncidentScoreCriticalThreshold
                        ? "scoringIncidentCritical"
                        : (incident.score > 0
                                ? "scoringIncidentWarning"
                                : "incidentNormal"));
}

const IncidentItem = ({incident}) => {
  return (
    <tr class={GetIncidentRowScoreClass(incident)}>
      <td>{incident.id}</td>
      <td>{incident.timestamp}</td>
      <td>{incident.sessionId}</td>
      <td>{incident.parentType}</td>
      <td>{incident.parentId}</td>
      <td>{incident.parentRecordId}</td>
      <td>{incident.usage}</td>
      <td>{incident.type}</td>
      <td>{incident.origin}</td>
      <td>{incident.group}</td>
      <td>{incident.score}</td>
      <td>{incident.code}</td>
      <td>{incident.name}</td>
      <td>{incident.value}</td>
      <td>{incident.message}</td>
      <td>{incident.metadata}</td>
    </tr>
  )
}

const IncidentList = ({incidents}) => {

  return (
    <table>
      <thead>
        <tr>
          <th>In RecID</th>
          <th>Timestamp</th>
          <th>Session ID</th>
          <th>PType</th>
          <th>Fingerprint ID</th>
          <th>Fp RecID</th>
          <th>Use</th>
          <th>Typ</th>
          <th>Org</th>
          <th>Grp</th>
          <th>Score</th>
          <th>Code</th>
          <th>Name</th>
          <th>Value</th>
          <th>Message</th>
          <th style={{width: 500 + 'px'}}>Metadata</th>
        </tr>
      </thead>
      <tbody>
        {incidents.map((incident) => <IncidentItem key={incident.id} incident={incident} />)}
      </tbody>
    </table>
  )
}

const IncidentPage = ({isAuthenticated, loadIncidents, getIncidentPage, setIncidentPage, urlLogin, incidents, showScoreAggregates, scoreAggregates}) => {

  const navigate = useNavigate();
  useEffect(() => { if (!isAuthenticated()) navigate(urlLogin); }, []);

  if (showScoreAggregates == 'true') {
//    console.log("Incident Page: Score aggregates:")
//    console.log(scoreAggregates);
    return (
      <>
      <h2>Score aggregates</h2>
      <ScoreAggregateList scoreAggregates={scoreAggregates} />
      <h2>Incidents</h2>
      <button onClick={() => setIncidentPage(0)}>First page</button>
      <button onClick={() => setIncidentPage(-1)}>Previous page</button>
      Page: { getIncidentPage() + 1 }
      <button onClick={() => setIncidentPage(1)}>Next page</button>
      <IncidentList incidents={incidents} />
      </>
    )
  } else {
    return (
      <>
      <h2>Incidents</h2>
      <button onClick={() => setIncidentPage(0)}>First page</button>
      <button onClick={() => setIncidentPage(-1)}>Previous page</button>
      Page: { getIncidentPage() + 1 }
      <button onClick={() => setIncidentPage(1)}>Next page</button>
      <IncidentList incidents={incidents} />
      </>
    )
  }
}

export default IncidentPage
