import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'

//const MonthSelectMenuOption = ({time, dateToStringConversionFunc}) => {
//  if (time == "") {
//    return (
//      <option value="" selected>
//        select time
//      </option>
//    )
//  } else {
//    return (
//      <option value={dateToStringConversionFunc(time)}>
//        {time.getMonth()+1}/{time.getFullYear()}
//      </option>
//    )
//  }
//}

//const MonthSelectMenu = ({menuName, menuId, getStateVar, setStateVar, dateToStringConversionFunc}) => {
//  var time = new Date();
//  time = new Date(time.getFullYear(), time.getMonth() + 1, 1);
//  const timestamps = [];
//  for (let i = 1; i < 14; i++) {
//    timestamps.push(time);
//    time = new Date(time.getFullYear(), time.getMonth() - 1, 1)
//  }
//  return (
//    <select name={menuName} id={menuId} value={getStateVar(menuName)} onChange={(e) => setStateVar(menuName, document.getElementById(menuName).value)}>
//      <MonthSelectMenuOption time="" />
//      {timestamps.map((timestamp) => <MonthSelectMenuOption time={timestamp} dateToStringConversionFunc={dateToStringConversionFunc} />)}
//    </select>
//  )
//}

const TableRow = ({row}) => {
  return (
    <tr>
      {row.map((item) => (
        <td>{item}</td>
      ))}
    </tr>
  )
}

const Table = ({table}) => {

  if (typeof table == 'undefined' || table == null || table == "" || table == [] || table.length < 2) {
    return "";
  }

  return (
    <table>
      <thead>
        <TableRow row={table[0]} />
      </thead>
      <tbody>
        {table.slice(1, table.length).map((row) => <TableRow row={row} />)}
      </tbody>
    </table>
  )
}

const CachePage = ({isAuthenticated, getCachePage, setCachePage, urlLogin, caches, loadCacheState, clearCache, cacheNames, setCacheName, getCacheName}) => {

  const navigate = useNavigate();
  useEffect(() => { if (!isAuthenticated()) navigate(urlLogin); }, []);

//      <MonthSelectMenu menuName="deviceVisitStatisticsFromDate" menuId="deviceVisitStatisticsFromDate" getStateVar={getStateVar} setStateVar={setStateVar} dateToStringConversionFunc={dateToStringConversionFunc} />
  return (
    <>
    <h2>Cache State</h2>

    <label>Cache name:</label>
    <select name="cacheName" id="selCacheName" defaultValue={getCacheName()} onChange={() => setCacheName(document.getElementById("selCacheName").value)} >
      <option value="">></option>
      {cacheNames.map((cacheName) => <option value={cacheName}>{cacheName}</option>)}
    </select>
    <p></p>

    <button onClick={() => setCachePage(0)}>First page</button>
    <button onClick={() => setCachePage(-1)}>Previous page</button>
    Page: { getCachePage() + 1 }
    <button onClick={() => setCachePage(1)}>Next page</button>

    <button onClick={() => loadCacheState()}>Refresh cache state</button>
    <button onClick={() => clearCache()}>Clear cache</button>
    <Table table={caches} />
    </>
  )
}

export default CachePage
