import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'

const FingerprintScoreCriticalThreshold = 1000;
const SessionScoreCriticalThreshold = 10000;

// !!! SHOULD BE THE SAME IN App.js !!!
const FILTER_FINGERPRINT = "FINGERPRINT"
const FILTER_SESSION = "SESSION"
const FILTER_DEVICE = "DEVICE"
const FILTER_IP_ADDRESS = "IP_ADDRESS"

const GetFingerprintRowScoreClass = (fingerprint) => {
  return fingerprint.scoreSession > SessionScoreCriticalThreshold
                ? "sessionCritical"
                : (fingerprint.scoreFingerprint > FingerprintScoreCriticalThreshold
                        ? "fingerprintCritical"
                        : (fingerprint.scoreSession > 0
                                ? "sessionWarning"
                                : (fingerprint.scoreFingerprint > 0
                                        ? "fingerprintWarning"
                                        : "fingerprintNormal")));
}

let loadFilteredFingerprintsFunc;

const FilterFingerprints = () => {
  loadFilteredFingerprintsFunc({
    fingerprintId: document.getElementById("txtFingerprintId").value,
    sessionId: document.getElementById("txtSessionId").value,
    deviceId: document.getElementById("txtDeviceId").value,
    ipAddress: document.getElementById("txtIpAddress").value,
    pixelCode: document.getElementById("selPixelCode").value,
    timestamp: document.getElementById("dtFilterTimestamp").value
  })
}

const ClearAllFilterFieldsExceptTimestamp = () => {
  document.getElementById("txtFingerprintId").value = "";
  document.getElementById("txtSessionId").value = "";
  document.getElementById("txtDeviceId").value = "";
  document.getElementById("txtIpAddress").value = "";
  document.getElementById("selPixelCode").value = "";
}

const SetFilterParameter = (parameterId, parameter) => {
  if (document.getElementById("radSelReplace").checked) ClearAllFilterFieldsExceptTimestamp();
  document.getElementById(parameterId).value = parameter;
  FilterFingerprints();
}

const SetFilterTimestamp = (parameter) => {
  // convert time string to date, add 1 second and convert back o time string with seconds precision
  let timestamp = new Date(parameter + "+00:00");               // we have to explicitly state that timestamp is in UTC
//  console.log(timestamp);
  timestamp.setSeconds(timestamp.getSeconds() + 1);             // add 1 second
//  console.log(timestamp);
  let timeString = timestamp.toISOString().substring(0, 19);    // convert to ISO string with seconds precision
//  console.log(timeString);
  document.getElementById("dtFilterTimestamp").value = timeString;
  FilterFingerprints();
}

const ClearFilterExceptTimestamp = () => {
  ClearAllFilterFieldsExceptTimestamp();
  FilterFingerprints();
}

//const SetFingerprintsFilterExceptTimestamp = (filterType, filterId) => {
//  document.getElementById("selFilterType").value = filterType;
//  document.getElementById("txtFilterId").value = filterId;
//  const filterTime = document.getElementById("dtFilterTimestamp").value;
//  loadFilteredFingerprintsFunc(filterType, filterId, filterTime);
//}

const FingerprintItem = ({fingerprint, fingerprintUrl, loadFilteredIncidents, loadFilteredFingerprints}) => {
//        S:{fingerprint.sessionId != null ? <Link onClick={() => SetFingerprintsFilterExceptTimestamp(FILTER_SESSION, fingerprint.sessionId)}>{fingerprint.sessionId}</Link> : "-"}<br></br>
//        F:{fingerprint.fingerprintId != null ? <Link onClick={() => SetFingerprintsFilterExceptTimestamp(FILTER_FINGERPRINT, fingerprint.fingerprintId)}>{fingerprint.fingerprintId}</Link> : "-"}<br></br>
//        D:{fingerprint.deviceId != null ? <Link onClick={() => SetFingerprintsFilterExceptTimestamp(FILTER_DEVICE, fingerprint.deviceId)}>{fingerprint.deviceId}</Link> : "-"}
  return (
    <tr class={GetFingerprintRowScoreClass(fingerprint)}>
      <td><Link to={`${fingerprintUrl}/${fingerprint.id}`}>{fingerprint.id}</Link></td>
      <td>{fingerprint.scoreAggregated}
        /{(fingerprint.scoreFingerprint != null ? <Link to={`incidents/fingerprint/${fingerprint.fingerprintId}`} onClick={() => loadFilteredIncidents(FILTER_FINGERPRINT, fingerprint.fingerprintId)}>{`${fingerprint.scoreFingerprint}`}</Link> : "-")}
        ({(fingerprint.scoreFingerprintInternal != null ? fingerprint.scoreFingerprintInternal : "-")}
        )/{(fingerprint.scoreSession != null ? <Link to={`incidents/session/${fingerprint.sessionId}`} onClick={() => loadFilteredIncidents(FILTER_SESSION, fingerprint.sessionId)}>{`${fingerprint.scoreSession}`}</Link> : "-")}
      </td>
      <td>{fingerprint.conversionOverhead}
        /{(fingerprint.scoreFingerprintOverhead != null ? fingerprint.scoreFingerprintOverhead : "-")}
        ({(fingerprint.scoreFingerprintInternalOverhead != null ? fingerprint.scoreFingerprintInternalOverhead : "-")}
        )/{(fingerprint.scoreSessionOverhead != null ? fingerprint.scoreSessionOverhead : "-")}
      </td>
      <td>{fingerprint.origin}</td>
      <td>
        S:{fingerprint.sessionId != null ? <Link onClick={() => SetFilterParameter("txtSessionId", fingerprint.sessionId)}>{fingerprint.sessionId}</Link> : "-"}<br></br>
        F:{fingerprint.fingerprintId != null ? <Link onClick={() => SetFilterParameter("txtFingerprintId", fingerprint.fingerprintId)}>{fingerprint.fingerprintId}</Link> : "-"}<br></br>
        D:{fingerprint.deviceId != null ? <><Link onClick={() => SetFilterParameter("txtDeviceId", fingerprint.deviceId)}>{fingerprint.deviceId}</Link>{fingerprint.deviceIsNew ? "(N)" : ""}</> : "-"}
      </td>
      <td>{fingerprint.fingerprintSequence}</td>
      <td>{fingerprint.raw.timestamp}({fingerprint.updateCount})<br></br>
          <Link onClick={() => SetFilterTimestamp(fingerprint.timestampCreated)}>{fingerprint.timestampCreated}</Link>({new Date(fingerprint.timestampCreated)-new Date(fingerprint.raw.timestamp)})<br></br>
          {fingerprint.timestampUpdated}({new Date(fingerprint.timestampUpdated)-new Date(fingerprint.timestampCreated)})</td>
      <td>GMT{(fingerprint.hostTimezone >= 0 ? "+" : "")}{fingerprint.hostTimezone}</td>
      <td>{(fingerprint.pixelType == "W" ? "WEB" : fingerprint.pixelType == "M" ? "MOBILE" : fingerprint.pixelType == "A" ? "ANDROID" : "UNKNOWN")}</td>
      <td>{fingerprint.deviceId != null ? <Link to={`incidents/device/${fingerprint.deviceId}`} onClick={() => loadFilteredIncidents(FILTER_DEVICE, fingerprint.deviceId)}>{`${fingerprint.deviceHashCode}`}</Link> : fingerprint.deviceHashCode}<br></br>
        {fingerprint.uaPlatform}<br></br>
        {fingerprint.uaBrowser}
      </td>
      <td>{fingerprint.hostIpAddressPublic != null ? <Link onClick={() => SetFilterParameter("txtIpAddress", fingerprint.hostIpAddressPublic)}>{fingerprint.hostIpAddressPublic}</Link> : "-"}<br></br>
        {fingerprint.ipCity}<br></br>
        {fingerprint.ipCountry}
      </td>
      <td>
        {fingerprint.pixelCode != null ? <Link onClick={() => SetFilterParameter("selPixelCode", fingerprint.pixelCode)}>{fingerprint.pixelCode}</Link> : "-"}
      </td>
      <td align="left">{fingerprint.urlProtocol + "//" + fingerprint.urlHost + (fingerprint.urlPort != null ? ":" + fingerprint.urlPort : "") + fingerprint.urlPath}</td>
    </tr>
  )
}

const FingerprintList = ({fingerprints, fingerprintsUrl, loadFilteredIncidents, loadFilteredFingerprints}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Score<br></br>D/F(i)/S</th>
          <th>Ovh<br></br>C/F(i)/S</th>
          <th>Origin</th>
          <th>Session/Fingerprint/Device ID</th>
          <th>Seq</th>
          <th>Timestamp Host(uCnt)/Cr/Upd</th>
          <th>Time zone</th>
          <th>Type</th>
          <th>Dev.Hash<br></br>Platf/Brows</th>
          <th>Remote IP/<br></br>City/Country</th>
          <th>Pixel code</th>
          <th>URL</th>
        </tr>
      </thead>
      <tbody>
        {fingerprints.map((fingerprint) => <FingerprintItem key={fingerprint.id} fingerprint={fingerprint} fingerprintUrl={fingerprintsUrl} loadFilteredIncidents={loadFilteredIncidents} loadFilteredFingerprints={loadFilteredFingerprints} />)}
      </tbody>
    </table>
  )
}

//const FilterFingerprints = () => {
//  const filterType = document.getElementById("selFilterType").value;
//  const filterId = document.getElementById("txtFilterId").value.trim();
//  const filterTime = document.getElementById("dtFilterTimestamp").value;
//  if (filterType == null || filterType == undefined) filterType = "";
//  if (filterId == null || filterId == undefined) filterId = "";
//  if (filterType == "" && filterId != "" || filterType != "" && filterId == "") {
//    alert("Specify both filter type and id to filter");
//  } else {
//    loadFilteredFingerprintsFunc(filterType, filterId, filterTime);
//  }
//}

//const ShowTime = () => {
//  alert("Time string is " + document.getElementById("dtFilterTimestamp").value);
//}

const FingerprintPage = ({isAuthenticated, getFingerprintPage, setFingerprintPage, urlLogin, fingerprints, resources, fingerprintsUrl, loadFilteredIncidents, loadFilteredFingerprints, getFingerprintFilterSettings, setFilterModeReplace, getFilterModeReplace}) => {

  const navigate = useNavigate();
  useEffect(() => { if (!isAuthenticated()) navigate(urlLogin); }, []);

  loadFilteredFingerprintsFunc = loadFilteredFingerprints;

  let filter = getFingerprintFilterSettings();
//  console.log("type: " + fingerprintsFilterType + ", id: " + fingerprintsFilterId + ", ts: " + fingerprintsFilterTimestamp);

//          <input type="datetime-local" name="filterTimestamp" id="dtFilterTimestamp" onChange={() => ShowTime()}/>
//          <input type="button" name="clearFilter" id="btnClearFilter" value="Show all" onClick={() => SetFingerprintsFilterExceptTimestamp("", "")}/>
//      <tr>
//        <td align="right"><label>Filter type</label></td>
//        <td align="left">
//          <select name="filterType" id="selFilterType" defaultValue={fingerprintsFilterType}>
//            <option value="">---Choose filter type---</option>
//            <option value={FILTER_FINGERPRINT}>{FILTER_FINGERPRINT}</option>
//            <option value={FILTER_SESSION}>{FILTER_SESSION}</option>
//            <option value={FILTER_DEVICE}>{FILTER_DEVICE}</option>
//            <option value={FILTER_IP_ADDRESS}>{FILTER_IP_ADDRESS}</option>
//          </select>
//        </td>
//      </tr>
//      <tr>
//        <td align="right"><label>Filter value</label></td>
//        <td align="left"><input type="text" size="40" name="filterId" id="txtFilterId" placeholder="Enter filter ID" defaultValue={fingerprintsFilterId}/></td>
//      </tr>
  return (
    <>
    <h2>Fingerprints</h2>
    <table align="center"><tbody>
      <tr>
        <td align="right"><label>Fingerprint selection method</label></td>
        <td>
          <fieldset align="left">
            <input type="radio" id="radSelReplace" name="fprtSelMethod" value="REPLACE" checked={getFilterModeReplace()} onChange={() => setFilterModeReplace(document.getElementById("radSelReplace").checked)} />
            <label for="radSelReplace">REPLACE</label>
            <input type="radio" id="radSelNarrow" name="fprtSelMethod" value="NARROW" checked={!getFilterModeReplace()} onChange={() => setFilterModeReplace(document.getElementById("radSelReplace").checked)} />
            <label for="radSelNarrow">NARROW</label>
          </fieldset>
        </td>
      </tr>
      <tr>
        <td align="right"><label>Fingerprint ID</label></td>
        <td align="left">
          <input type="text" size="40" name="fingerprintId" id="txtFingerprintId" placeholder=">" defaultValue={filter.fingerprintId}/>
          <input type="button" name="clearFingerprintId" id="btnClearFingerprintId" value="Clear" onClick={() => {document.getElementById("txtFingerprintId").value = ""}} />
        </td>
      </tr>
      <tr>
        <td align="right"><label>Session ID</label></td>
        <td align="left">
          <input type="text" size="40" name="sessionId" id="txtSessionId" placeholder=">" defaultValue={filter.sessionId}/>
          <input type="button" name="clearSessionId" id="btnClearSessionId" value="Clear" onClick={() => {document.getElementById("txtSessionId").value = ""}} />
        </td>
      </tr>
      <tr>
        <td align="right"><label>Device ID</label></td>
        <td align="left">
          <input type="text" size="40" name="deviceId" id="txtDeviceId" placeholder=">" defaultValue={filter.deviceId}/>
          <input type="button" name="clearDeviceId" id="btnClearDeviceId" value="Clear" onClick={() => {document.getElementById("txtDeviceId").value = ""}} />
        </td>
      </tr>
      <tr>
        <td align="right"><label>IP address</label></td>
        <td align="left">
          <input type="text" size="40" name="ipAddress" id="txtIpAddress" placeholder=">" defaultValue={filter.ipAddress}/>
          <input type="button" name="clearIpAddress" id="btnClearIpAddress" value="Clear" onClick={() => {document.getElementById("txtIpAddress").value = ""}} />
        </td>
      </tr>
      <tr>
        <td align="right"><label>Pixel code</label></td>
        <td align="left">
          <select name="pixelCode" id="selPixelCode" defaultValue={filter.pixelCode}>
            <option value="">></option>
            {resources.map((resource) => <option value={resource.pixelCode}>{resource.pixelCode}</option>)}
          </select>
          <input type="button" name="clearPixelCode" id="btnClearPixelCode" value="Clear" onClick={() => {document.getElementById("selPixelCode").value = ""}} />
        </td>
      </tr>
      <tr>
        <td align="right"><label>Start time</label></td>
        <td align="left">
          <input type="datetime-local" name="filterTimestamp" id="dtFilterTimestamp" defaultValue={filter.timestamp} />
          <input type="button" name="setTimestampNow" id="btnSetTimestampNow" value="Now" onClick={() => {document.getElementById("dtFilterTimestamp").value = ""}} />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <input type="button" name="setFilter" id="btnSetFilter" value="Filter" onClick={() => FilterFingerprints()}/>
          <input type="button" name="clearFilter" id="btnClearFilter" value="Show all" onClick={() => ClearFilterExceptTimestamp()}/>
        </td>
      </tr>
    </tbody></table>
    <br></br>
    <button onClick={() => setFingerprintPage(0)}>First page</button>
    <button onClick={() => setFingerprintPage(-1)}>Previous page</button>
    Page: { getFingerprintPage() + 1 }
    <button onClick={() => setFingerprintPage(1)}>Next page</button>
    <FingerprintList fingerprints={fingerprints} fingerprintsUrl={fingerprintsUrl} loadFilteredIncidents={loadFilteredIncidents} loadFilteredFingerprints={loadFilteredFingerprints} />
    </>
  )
}

export default FingerprintPage
