import React from 'react';


class LoginForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      username: '', password: '',
      regUsername: '', regPassword: '', regPassword2: '', regEmail: '', regToken: ''
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleLogin(event) {
//    console.log('LoginForm: ' + this.state.username + ' ' + this.state.password)
//    alert("Login")
    event.preventDefault()
    if (this.state.username == '' || this.state.password == '') {
      alert('Username and password should be provided');
    } else {
      this.props.login(this.state.username, this.state.password)
    }
  }

  handleRegister(event) {
//    console.log('LoginForm: ' + this.state.username + ' ' + this.state.password)
//    alert("Register")
    event.preventDefault()
    if (this.state.regUsername == '' || this.state.regPassword == '' || this.state.regPassword2 == '' ||
        this.state.regEmail == '' || this.state.regToken == '') {
      alert('All the registration information should be provided');
    } else if (this.state.regPassword != this.state.regPassword2) {
      alert('Passwords should match');
    } else {
      this.props.registerUser(this.state.regUsername, this.state.regPassword, this.state.regEmail, this.state.regToken)
    }
  }

  render() {
    return (
      <>
      <h2>Login</h2>
      <table align="center"><tbody><tr><td valign="top">
        <form onSubmit={(event) => this.handleLogin(event)} id="formLogin">
          <table><tbody>
            <tr><td colSpan="2">LOGIN</td></tr>
            <tr>
              <td align="right">Username:</td>
              <td><input type="text" name="username" placeholder="username" value={this.state.username} onChange={(event) => this.handleChange(event)} /></td>
            </tr>
            <tr>
              <td align="right">Password:</td>
              <td><input type="password" name="password" placeholder="password" value={this.state.password} onChange={(event) => this.handleChange(event)} /></td>
            </tr>
            <tr>
              <td colSpan="2"><input type="submit" value="Login" /></td>
            </tr>
          </tbody></table>
        </form>
      </td><td valign="top">
        <form onSubmit={(event) => this.handleRegister(event)} id="formRegister">
          <table><tbody>
            <tr><td colSpan="2">REGISTER</td></tr>
            <tr>
              <td align="right">Username:</td>
              <td><input type="text" name="regUsername" placeholder="username" value={this.state.regUsername} onChange={(event) => this.handleChange(event)} /></td>
            </tr>
            <tr>
              <td align="right">Password:</td>
              <td><input type="password" name="regPassword" placeholder="password" value={this.state.regPassword} onChange={(event) => this.handleChange(event)} /></td>
            </tr>
            <tr>
              <td align="right">Repeat password:</td>
              <td><input type="password" name="regPassword2" placeholder="password" value={this.state.regPassword2} onChange={(event) => this.handleChange(event)} /></td>
            </tr>
            <tr>
              <td align="right">Email:</td>
              <td><input type="email" name="regEmail" placeholder="email" value={this.state.regEmail} onChange={(event) => this.handleChange(event)} /></td>
            </tr>
            <tr>
              <td align="right">Token:</td>
              <td><input type="text" name="regToken" placeholder="token" value={this.state.regToken} onChange={(event) => this.handleChange(event)} /></td>
            </tr>
            <tr>
              <td colSpan="2"><input type="submit" value="Register" /></td>
            </tr>
          </tbody></table>
        </form>
      </td></tr></tbody></table>
      </>
    );
  }

}

export default LoginForm
